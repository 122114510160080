import React from 'react';

import { ContactFormBannerBig, Layout, SEO } from '../components';

export default function Contact(props) {
  return (
    <Layout {...props}>
      <SEO title="Contact Us" pathname={props.location.pathname} />
      <ContactFormBannerBig />
    </Layout>
  );
}
